import React from 'react'
import gif from '../img/turn-below.gif'
import testimoni1 from '../img/testi-2.jpg'
import testimoni2 from '../img/testi-3.jpg'
import testimoni3 from '../img/testi-4.jpg'
import testimoni4 from '../img/testi-5.jpg'
import testimoni5 from '../img/testi-7.jpg'
import testimoni6 from '../img/testi-8.jpg'
import productasli1 from '../img/8fc1bd01-2781-4704-90e4-4c4e7f328124.jpg'
import productasli2 from '../img/Untitled-2-1.jpg'
import productasli3 from '../img/Untitled-1.jpg'
import manfaat from '../img/a1f02e3c0f5c6d9bdcc7f9026f7ada30.jpg'
import footer from '../img/Untitled-3-1.jpg'
import footer1 from '../img/LABEL-1024x607-1-1-1-2-1-768x455-1.jpg'
import footer2 from '../img/Untitled-4-1.jpg'
import sisir from '../img/350px-Plastic_comb,_2015-06-07.jpg'


const Home = () => {
  return (
    <>
      <div class=" w-screen flex justify-center items-center text-center md:h-screen md:flex-col">
        <img className='hidden md:block w-[50%]' src={sisir} alt="sisir" />
        <div class=" w-full shadow-md md:hidden ">
          <section class="p-4">
            <hr class="bg-black border-none h-[2px]" />
            <br />
            <h1 class="text-4xl font-bold text-center">Terima Kasih Ya Sudah Klik Iklan!</h1>
            <br />
            <hr class="bg-black border-none h-[2px]" />
            <br />
            <h2 class="text-center font-bold text-4xl"><span class="text-red-600">Testimoni</span> Dari Pelanggan...</h2>
            <img class="my-4" src={gif} alt="Minyak Lintah Papua" />
            <div class="grid grid-cols-1 gap-4">
              <img class="w-full rounded-xl" src={testimoni1} alt="Minyak Lintah Papua" />
              <img class="w-full rounded-xl" src={testimoni2} alt="Minyak Lintah Papua" />
              <img class="w-full rounded-xl" src={testimoni3} alt="Minyak Lintah Papua" />
              <img class="w-full rounded-xl" src={testimoni4} alt="Minyak Lintah Papua" />
              <img class="w-full rounded-xl" src={testimoni5} alt="Minyak Lintah Papua" />
              <img class="w-full rounded-xl" src={testimoni6} alt="Minyak Lintah Papua" />
            </div>
            <br />
            <hr class="border-none h-[2px] bg-black" />
            <br />
            <h1 class="text-center text-3xl font-bold">Product Kami <span class="text-red-600">Ber-segel</span> 100% <span class="text-red-600">Asli</span></h1>
            <br />
            <img class="w-full rounded-lg" src={productasli1} alt="Minyak Lintah Papua" />
            <br />
            <img class="w-full rounded-lg" src={productasli2} alt="Minyak Lintah Papua" />
            <br />
            <hr class="border-none h-[2px] bg-black" />
            <br />
            <h1 class="text-3xl font-bold">Ini <span class="text-red-600">Manfaat</span> dan <span class="text-red-600">Khasiatnya</span></h1>
            <br />
            <img class="w-full rounded-lg" src={manfaat} alt="Minyak Lintah Papua" />
            <br />
            <ul class="text-left list-disc px-5 text-lg">
              <li>Memperbesar Ukuran Alat vital</li>
              <li>Memelihara Sistem Produksi</li>
              <li>Meningkatkan Rangsangan Seksual</li>
              <li>Mengatasi Ejakulasi Dini</li>
              <li>Mengeraskan Otot Penis dan Buah Zakar</li>
              <li>Mengatasi Impotensi</li>
              <li>meningkatkan Vitalitas Pria</li>
            </ul>
            <br />
            <hr class="border-none h-[2px] bg-black" />
            <br />
            <img class="w-full rounded-lg" src={productasli3} alt="Minyak Lintah Papua" />
            <br />
            <p>
              <span class="text-red-600 font-bold">Hati-hati</span> dengan produk palsu yang dapat membahayakan kesehatan anda, pastikan anda membeli di agen resmi dengan produk yang
              <span class="text-red-600 font-bold"> Bergaransi Original 100%</span>
            </p>
            <br />
            <hr class="border-none h-[2px] bg-black" />
            <br />
            <h2 class="font-bold text-2xl">
              Mau Beli <br />
              Harganya Berapa Ya?
            </h2>
            <h3 class="font-bold text-4xl">Harga Normal <br /><span class="line-through text-red-600">Rp. 298.000,-</span></h3>
            <h3 class="font-bold text-4xl">Harga Promo Spesial <br /><span>Rp. 149.000,-</span></h3>
            <br />
            <h3 class="font-bold text-2xl text-red-600">Stok Terbatas</h3>
            <br />
            <div><a class="py-3 px-5 rounded-full bg-red-600 text-white mt-4 border-4 border-dotted border-white" href="/">Ambil Promo Sekarang</a></div>
            <div><a class="fixed bottom-4 left-14 right-14 py-3 px-5 rounded-full bg-red-600 text-white mt-4 border-4 border-dotted border-white" href="/">Ambil Promo Sekarang</a></div>
            <br />
            <br></br>
            <hr class="border-none h-[2px] bg-black" />
            <br />
            <img class="w-full rounded-lg" src={footer} alt="Minyak Lintah Papua" />
            <img class="w-full rounded-lg" src={footer1} alt="Minyak Lintah Papua" />
            <img class="w-full rounded-lg" src={footer2} alt="Minyak Lintah Papua" />
          </section>
        </div>
      </div>
    </>
  )
}



export default Home